import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useConfig from "hooks/api/useConfig";
import useUpdateConfig from "hooks/api/useUpdateConfig";
import useFetchWithAuth from "hooks/useFetchWithAuth";
import Loader from "components/Loader/Loader";
import {
	Box,
	Button,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Switch,
	FormControlLabel,
	Typography,
	Grid,
	Divider,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	IconButton,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Alert,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import frontendconfig from "config";

const Agent = () => {
	const { agentName } = useParams();
	const config = useConfig("retell_agents");
	const updateConfig = useUpdateConfig("retell_agents");
	const [agentConfig, setAgentConfig] = useState(null);
	const [voices, setVoices] = useState([]);
	const fetchWithAuth = useFetchWithAuth();
	const [playingAudio, setPlayingAudio] = useState(null);
	const audioRef = useRef(new Audio());
	const navigate = useNavigate();
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

	useEffect(() => {
		if (config.data?.data) {
			const agent = config.data.data.find((a) => a.agent_name === agentName);
			setAgentConfig(agent);
		}
	}, [config.data, agentName]);

	useEffect(() => {
		const fetchVoices = async () => {
			try {
				const response = await fetchWithAuth(
					`${frontendconfig.backend_url}/retell/voices`
				);
				const data = await response.json();
				setVoices(data);
			} catch (error) {
				console.error("Error fetching voices:", error);
				toast.error("Failed to fetch voice options");
			}
		};

		fetchVoices();
	}, [fetchWithAuth]);

	const syncAgentNameWithNickname = (agent) => {
		return {
			...agent,
			phone_number: {
				...agent.phone_number,
				nickname: agent.agent_name,
			},
		};
	};

	const handleInputChange = (field, value) => {
		setAgentConfig((prev) => {
			const updatedConfig = { ...prev, [field]: value };
			if (field === "agent_name") {
				return syncAgentNameWithNickname(updatedConfig);
			}
			return updatedConfig;
		});
	};

	const handleSaveClick = async () => {
		const updatedAgents = config.data.data.map((a) =>
			a.agent_name === agentName ? syncAgentNameWithNickname(agentConfig) : a
		);

		await updateConfig
			.mutateAsync({
				name: "retell_agents",
				data: updatedAgents,
				commit_message: `Agent ${agentName} updated`,
			})
			.then(() => {
				toast.success("Agent updated successfully!");
				config.refetch();
			})
			.catch((err) => {
				toast.error("Oops! Something went wrong. Please try again");
			});
	};

	const handlePlayAudio = (previewUrl) => {
		if (playingAudio === previewUrl) {
			audioRef.current.pause();
			setPlayingAudio(null);
		} else {
			audioRef.current.src = previewUrl;
			audioRef.current.play();
			setPlayingAudio(previewUrl);
		}
	};

	useEffect(() => {
		const handleAudioEnded = () => setPlayingAudio(null);
		audioRef.current.addEventListener("ended", handleAudioEnded);
		return () => {
			audioRef.current.removeEventListener("ended", handleAudioEnded);
		};
	}, []);

	const handleAddAgent = async () => {
		const newAgent = syncAgentNameWithNickname({
			agent_name: "New Agent",
			type: "default",
			voice_id: "11labs-Adrian",
			enable_backchannel: true,
			end_call_after_silence_ms: 25000,
			interruption_sensitivity: 1,
			language: "en-US",
			normalize_for_speech: false,
			opt_out_sensitive_data_storage: false,
			pronunciation_dictionary: [],
			reminder_max_count: 2,
			reminder_trigger_ms: 10000,
			responsiveness: 1,
			voice_speed: 1,
			voice_temperature: 1,
			llm: {
				begin_message: "",
				general_prompt: "",
				general_tools: [],
				model: "gpt-4o",
			},
			phone_number: {},
		});

		const updatedAgents = [...config.data.data, newAgent];

		try {
			await updateConfig.mutateAsync({
				name: "retell_agents",
				data: updatedAgents,
				commit_message: "New agent added",
			});
			toast.success("New agent added successfully!");
			config.refetch();
			navigate(`/agents/${newAgent.agent_name}`);
		} catch (err) {
			toast.error("Failed to add new agent. Please try again.");
		}
	};

	const handleDuplicateAgent = async () => {
		const duplicatedAgent = syncAgentNameWithNickname({
			...agentConfig,
			agent_name: `${agentConfig.agent_name} (Copy)`,
		});

		const updatedAgents = [...config.data.data, duplicatedAgent];

		try {
			await updateConfig.mutateAsync({
				name: "retell_agents",
				data: updatedAgents,
				commit_message: `Agent ${agentConfig.agent_name} duplicated`,
			});
			toast.success("Agent duplicated successfully!");
			config.refetch();
			navigate(`/agents/${duplicatedAgent.agent_name}`);
		} catch (err) {
			toast.error("Failed to duplicate agent. Please try again.");
		}
	};

	const handleDeleteAgent = async () => {
		setDeleteConfirmOpen(true);
	};

	const confirmDeleteAgent = async () => {
		const updatedAgents = config.data.data.filter(
			(a) => a.agent_name !== agentName
		);

		try {
			await updateConfig.mutateAsync({
				name: "retell_agents",
				data: updatedAgents,
				commit_message: `Agent ${agentName} deleted`,
			});
			toast.success("Agent deleted successfully!");
			config.refetch();
			navigate("/agents");
		} catch (err) {
			toast.error("Failed to delete agent. Please try again.");
		}
		setDeleteConfirmOpen(false);
	};

	if (config.isLoading || !agentConfig) {
		return <Loader />;
	}

	const isCustomAgent = agentConfig.type === "custom";

	return (
		<Box sx={{ mt: 5, px: 4 }}>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					mb: 3,
				}}
			>
				<Typography variant="h4" gutterBottom>
					Agent Configuration
				</Typography>
				<Box sx={{ display: "flex", gap: 2 }}>
					<Button
						variant="contained"
						onClick={handleSaveClick}
						disabled={updateConfig.isLoading}
					>
						{updateConfig.isLoading ? "Saving..." : "Save"}
					</Button>
					<Button variant="contained" color="primary" onClick={handleAddAgent}>
						Add Agent
					</Button>
				</Box>
			</Box>
			<Grid container spacing={3}>
				{/* Basic Information */}
				<Grid item xs={12}>
					<Accordion defaultExpanded variant="outlined">
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<Typography variant="h6">Basic Information</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Grid container spacing={2}>
								<Grid item xs={12} md={6}>
									<TextField
										fullWidth
										label="Agent Name"
										value={agentConfig.agent_name}
										onChange={(e) =>
											handleInputChange("agent_name", e.target.value)
										}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<FormControl fullWidth>
										<InputLabel>Voice ID</InputLabel>
										<Select
											value={agentConfig.voice_id}
											onChange={(e) =>
												handleInputChange("voice_id", e.target.value)
											}
										>
											{voices.map((voice) => (
												<MenuItem key={voice.voice_id} value={voice.voice_id}>
													<Box
														sx={{
															display: "flex",
															alignItems: "center",
															width: "100%",
														}}
													>
														<Box sx={{ flexGrow: 1 }}>
															<Typography variant="subtitle1">
																{voice.voice_name} ({voice.gender},{" "}
																{voice.accent})
															</Typography>
															<Typography
																variant="caption"
																color="textSecondary"
															>
																{voice.age} | {voice.provider} |{" "}
																{voice.voice_type}
															</Typography>
														</Box>
														<IconButton
															onClick={(e) => {
																e.stopPropagation();
																handlePlayAudio(voice.preview_audio_url);
															}}
															size="small"
														>
															{playingAudio === voice.preview_audio_url ? (
																<PauseIcon />
															) : (
																<PlayArrowIcon />
															)}
														</IconButton>
													</Box>
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} md={6}>
									<FormControl fullWidth>
										<InputLabel>Voice Model</InputLabel>
										<Select
											value={agentConfig.voice_model || ""}
											onChange={(e) =>
												handleInputChange("voice_model", e.target.value)
											}
										>
											<MenuItem value={null}>Auto</MenuItem>
											<MenuItem value="eleven_turbo_v2">
												Elevent Turbo V2
												<span className="text-xs text-gray-500">
													Fast English only model, supports pronunciation tags
												</span>
											</MenuItem>
											<MenuItem value="eleven_turbo_v2_5">
												Eleven Turbo V2.5
												<span className="text-xs text-gray-500">
													Multilingual model with lowest latency
												</span>
											</MenuItem>
											<MenuItem value="eleven_multilingual_v2">
												Eleven Multilingual V2
												<span className="text-xs text-gray-500">
													Multilingual model with rich emotion and nice accent
												</span>
											</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} md={6}>
									<FormControl fullWidth>
										<InputLabel>Agent Type</InputLabel>
										<Select
											value={agentConfig.type}
											onChange={(e) =>
												handleInputChange("type", e.target.value)
											}
										>
											<MenuItem value="default">Default</MenuItem>
											<MenuItem value="custom">Custom</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12}>
									<Box sx={{ display: "flex", gap: 2, mt: 2 }}>
										<Button variant="outlined" onClick={handleDuplicateAgent}>
											Duplicate Agent
										</Button>
										<Button
											variant="outlined"
											color="error"
											onClick={handleDeleteAgent}
										>
											Delete Agent
										</Button>
									</Box>
								</Grid>
							</Grid>
						</AccordionDetails>
					</Accordion>
				</Grid>

				{/* Two-column layout */}
				<Grid item xs={12}>
					<Grid container spacing={3}>
						{/* Larger column: Single Prompt Agent */}
						<Grid item xs={12} md={8}>
							<Accordion defaultExpanded variant="outlined">
								<AccordionSummary expandIcon={<ExpandMoreIcon />}>
									<Typography variant="h6">Single Prompt Agent</Typography>
								</AccordionSummary>
								<AccordionDetails>
									{isCustomAgent && (
										<Alert severity="info" sx={{ mb: 2 }}>
											This is a custom agent. Single Prompt Agent settings are
											disabled.
										</Alert>
									)}
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<TextField
												fullWidth
												multiline
												rows={4}
												label="Prompt"
												value={agentConfig.llm?.general_prompt || ""}
												onChange={(e) =>
													handleInputChange("llm", {
														...agentConfig.llm,
														general_prompt: e.target.value,
													})
												}
												disabled={isCustomAgent}
											/>
										</Grid>
										<Grid item xs={12} md={6}>
											<FormControl fullWidth>
												<InputLabel>LLM Model</InputLabel>
												<Select
													value={agentConfig.llm?.model || ""}
													onChange={(e) =>
														handleInputChange("llm", {
															...agentConfig.llm,
															model: e.target.value,
														})
													}
													disabled={isCustomAgent}
												>
													{[
														"gpt-3.5-turbo",
														"gpt-4-turbo",
														"gpt-4o",
														"gpt-4o-mini",
														"claude-3.5-sonnet",
														"claude-3-haiku",
													].map((model) => (
														<MenuItem key={model} value={model}>
															{model}
														</MenuItem>
													))}
												</Select>
											</FormControl>
										</Grid>
										<Grid item xs={12} md={6}>
											<FormControl fullWidth>
												<InputLabel>Who Speaks First</InputLabel>
												<Select
													value={
														agentConfig.llm?.begin_message === undefined
															? "ai_dynamic"
															: agentConfig.llm?.begin_message === ""
															? "user"
															: "ai_static"
													}
													onChange={(e) => {
														const value = e.target.value;
														let newBeginMessage;
														if (value === "ai_dynamic") {
															newBeginMessage = undefined;
														} else if (value === "user") {
															newBeginMessage = "";
														} else {
															newBeginMessage =
																agentConfig.llm?.begin_message ||
																"Hello, how can I assist you today?";
														}
														handleInputChange("llm", {
															...agentConfig.llm,
															begin_message: newBeginMessage,
														});
													}}
													disabled={isCustomAgent}
												>
													<MenuItem value="ai_dynamic">
														AI begins with dynamic message
													</MenuItem>
													<MenuItem value="ai_static">
														AI begins with begin message
													</MenuItem>
													<MenuItem value="user">User speaks first</MenuItem>
												</Select>
											</FormControl>
										</Grid>
										{agentConfig.llm?.begin_message !== undefined &&
											agentConfig.llm?.begin_message !== "" && (
												<Grid item xs={12}>
													<TextField
														fullWidth
														label="Begin Message"
														value={agentConfig.llm?.begin_message || ""}
														onChange={(e) =>
															handleInputChange("llm", {
																...agentConfig.llm,
																begin_message: e.target.value,
															})
														}
														disabled={isCustomAgent}
													/>
												</Grid>
											)}
										{/* General Tools section */}
										<Grid item xs={12}>
											<Typography variant="subtitle1" gutterBottom>
												General Tools
											</Typography>
											{agentConfig.llm?.general_tools?.map((tool, index) => (
												<Box
													key={index}
													sx={{
														mb: 2,
														p: 2,
														border: "1px solid #e0e0e0",
														borderRadius: "4px",
														position: "relative",
													}}
												>
													<IconButton
														sx={{
															position: "absolute",
															top: 8,
															right: 8,
														}}
														onClick={() => {
															const updatedTools =
																agentConfig.llm.general_tools.filter(
																	(_, i) => i !== index
																);
															handleInputChange("llm", {
																...agentConfig.llm,
																general_tools: updatedTools,
															});
														}}
														disabled={isCustomAgent}
													>
														<DeleteIcon />
													</IconButton>
													<Grid container spacing={2}>
														<Grid item xs={12} md={6}>
															<TextField
																fullWidth
																label="Tool Name"
																value={tool.name}
																onChange={(e) => {
																	const updatedTools = [
																		...agentConfig.llm.general_tools,
																	];
																	updatedTools[index] = {
																		...tool,
																		name: e.target.value,
																	};
																	handleInputChange("llm", {
																		...agentConfig.llm,
																		general_tools: updatedTools,
																	});
																}}
																disabled={isCustomAgent}
															/>
														</Grid>
														<Grid item xs={12} md={6}>
															<FormControl fullWidth>
																<InputLabel>Tool Type</InputLabel>
																<Select
																	value={tool.type}
																	onChange={(e) => {
																		const updatedTools = [
																			...agentConfig.llm.general_tools,
																		];
																		updatedTools[index] = {
																			...tool,
																			type: e.target.value,
																		};
																		handleInputChange("llm", {
																			...agentConfig.llm,
																			general_tools: updatedTools,
																		});
																	}}
																	disabled={isCustomAgent}
																>
																	<MenuItem value="end_call">End Call</MenuItem>
																	<MenuItem value="custom">Custom</MenuItem>
																</Select>
															</FormControl>
														</Grid>
														<Grid item xs={12}>
															<TextField
																fullWidth
																multiline
																rows={2}
																label="Description"
																value={tool.description}
																onChange={(e) => {
																	const updatedTools = [
																		...agentConfig.llm.general_tools,
																	];
																	updatedTools[index] = {
																		...tool,
																		description: e.target.value,
																	};
																	handleInputChange("llm", {
																		...agentConfig.llm,
																		general_tools: updatedTools,
																	});
																}}
																disabled={isCustomAgent}
															/>
														</Grid>
														{tool.type === "custom" && (
															<>
																<Grid item xs={12}>
																	<TextField
																		fullWidth
																		label="URL"
																		value={tool.url}
																		onChange={(e) => {
																			const updatedTools = [
																				...agentConfig.llm.general_tools,
																			];
																			updatedTools[index] = {
																				...tool,
																				url: e.target.value,
																			};
																			handleInputChange("llm", {
																				...agentConfig.llm,
																				general_tools: updatedTools,
																			});
																		}}
																		disabled={isCustomAgent}
																	/>
																</Grid>
																<Grid item xs={12} md={6}>
																	<FormControlLabel
																		control={
																			<Switch
																				checked={tool.speak_during_execution}
																				onChange={(e) => {
																					const updatedTools = [
																						...agentConfig.llm.general_tools,
																					];
																					updatedTools[index] = {
																						...tool,
																						speak_during_execution:
																							e.target.checked,
																					};
																					handleInputChange("llm", {
																						...agentConfig.llm,
																						general_tools: updatedTools,
																					});
																				}}
																				disabled={isCustomAgent}
																			/>
																		}
																		label="Speak During Execution"
																	/>
																</Grid>
																<Grid item xs={12} md={6}>
																	<FormControlLabel
																		control={
																			<Switch
																				checked={tool.speak_after_execution}
																				onChange={(e) => {
																					const updatedTools = [
																						...agentConfig.llm.general_tools,
																					];
																					updatedTools[index] = {
																						...tool,
																						speak_after_execution:
																							e.target.checked,
																					};
																					handleInputChange("llm", {
																						...agentConfig.llm,
																						general_tools: updatedTools,
																					});
																				}}
																				disabled={isCustomAgent}
																			/>
																		}
																		label="Speak After Execution"
																	/>
																</Grid>
															</>
														)}
													</Grid>
												</Box>
											))}
											<Button
												variant="outlined"
												onClick={() => {
													const newTool = {
														name: "",
														type: "end_call",
														description: "",
													};
													handleInputChange("llm", {
														...agentConfig.llm,
														general_tools: [
															...(agentConfig.llm?.general_tools || []),
															newTool,
														],
													});
												}}
												disabled={isCustomAgent}
											>
												Add Tool
											</Button>
										</Grid>
									</Grid>
								</AccordionDetails>
							</Accordion>
						</Grid>

						{/* Smaller column: Privacy Settings and Advanced Settings */}
						<Grid item xs={12} md={4}>
							{/* Privacy Settings */}
							<Accordion variant="outlined">
								<AccordionSummary expandIcon={<ExpandMoreIcon />}>
									<Typography variant="h6">Privacy Settings</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<FormControlLabel
												control={
													<Switch
														checked={
															agentConfig.opt_out_sensitive_data_storage ||
															false
														}
														onChange={(e) =>
															handleInputChange(
																"opt_out_sensitive_data_storage",
																e.target.checked
															)
														}
													/>
												}
												label="Opt Out of Sensitive Data Storage"
											/>
											<Typography variant="caption" display="block">
												If enabled, sensitive data like transcripts and
												recordings will not be stored.
											</Typography>
										</Grid>
									</Grid>
								</AccordionDetails>
							</Accordion>

							{/* Advanced Settings */}
							<Accordion variant="outlined">
								<AccordionSummary expandIcon={<ExpandMoreIcon />}>
									<Typography variant="h6">Advanced Settings</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<FormControl fullWidth>
												<InputLabel>Ambient Sound</InputLabel>
												<Select
													value={agentConfig.ambient_sound || ""}
													onChange={(e) =>
														handleInputChange("ambient_sound", e.target.value)
													}
												>
													<MenuItem value="">None</MenuItem>
													<MenuItem value="coffee-shop">Coffee Shop</MenuItem>
													<MenuItem value="convention-hall">
														Convention Hall
													</MenuItem>
													<MenuItem value="summer-outdoor">
														Summer Outdoor
													</MenuItem>
													<MenuItem value="mountain-outdoor">
														Mountain Outdoor
													</MenuItem>
													<MenuItem value="static-noise">Static Noise</MenuItem>
													<MenuItem value="call-center">Call Center</MenuItem>
												</Select>
											</FormControl>
										</Grid>
										<Grid item xs={12}>
											<TextField
												fullWidth
												type="number"
												label="Ambient Sound Volume"
												value={agentConfig.ambient_sound_volume || 1}
												onChange={(e) =>
													handleInputChange(
														"ambient_sound_volume",
														parseFloat(e.target.value)
													)
												}
												inputProps={{ step: 0.1, min: 0, max: 2 }}
												helperText="Value ranging from 0 to 2"
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												fullWidth
												type="number"
												label="Responsiveness"
												value={agentConfig.responsiveness || 1}
												onChange={(e) =>
													handleInputChange(
														"responsiveness",
														parseFloat(e.target.value)
													)
												}
												inputProps={{ step: 0.1, min: 0, max: 1 }}
												helperText="Value ranging from 0 to 1"
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												fullWidth
												type="number"
												label="Interruption Sensitivity"
												value={agentConfig.interruption_sensitivity || 1}
												onChange={(e) =>
													handleInputChange(
														"interruption_sensitivity",
														parseFloat(e.target.value)
													)
												}
												inputProps={{ step: 0.1, min: 0, max: 1 }}
												helperText="Value ranging from 0 to 1"
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												fullWidth
												type="number"
												label="Voice Speed"
												value={agentConfig.voice_speed || 1}
												onChange={(e) =>
													handleInputChange(
														"voice_speed",
														parseFloat(e.target.value)
													)
												}
												inputProps={{ step: 0.1, min: 0.5, max: 2 }}
												helperText="Value ranging from 0.5 to 2"
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												fullWidth
												type="number"
												label="Voice Temperature"
												value={agentConfig.voice_temperature || 1}
												onChange={(e) =>
													handleInputChange(
														"voice_temperature",
														parseFloat(e.target.value)
													)
												}
												inputProps={{ step: 0.1, min: 0, max: 2 }}
												helperText="Value ranging from 0 to 2"
											/>
										</Grid>
										<Grid item xs={12}>
											<Typography variant="subtitle2" gutterBottom>
												Reminder Message Frequency
											</Typography>
											<Box display="flex" alignItems="center">
												<TextField
													type="number"
													label="Trigger After (seconds)"
													value={Math.floor(
														(agentConfig.reminder_trigger_ms || 10000) / 1000
													)}
													onChange={(e) =>
														handleInputChange(
															"reminder_trigger_ms",
															parseInt(e.target.value) * 1000
														)
													}
													inputProps={{ min: 0 }}
													sx={{ mr: 2 }}
												/>
												<Typography variant="body2" sx={{ mr: 2 }}>
													seconds, up to
												</Typography>
												<TextField
													type="number"
													label="Max Count"
													value={agentConfig.reminder_max_count || 1}
													onChange={(e) =>
														handleInputChange(
															"reminder_max_count",
															parseInt(e.target.value)
														)
													}
													inputProps={{ min: 0 }}
												/>
												<Typography variant="body2" sx={{ ml: 2 }}>
													times if there is no response.
												</Typography>
											</Box>
										</Grid>
										<Grid item xs={12}>
											<TextField
												fullWidth
												label="Boosted Keywords"
												value={
													agentConfig.boosted_keywords
														? agentConfig.boosted_keywords.join(", ")
														: ""
												}
												onChange={(e) =>
													handleInputChange(
														"boosted_keywords",
														e.target.value.split(", ").filter(Boolean)
													)
												}
												helperText="Comma-separated list of keywords to bias the transcriber model"
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												fullWidth
												label="Fallback Voice IDs"
												value={
													agentConfig.fallback_voice_ids
														? agentConfig.fallback_voice_ids.join(", ")
														: ""
												}
												onChange={(e) =>
													handleInputChange(
														"fallback_voice_ids",
														e.target.value.split(", ").filter(Boolean)
													)
												}
												helperText="Comma-separated list of fallback voice IDs"
											/>
										</Grid>
										<Grid item xs={12}>
											<FormControlLabel
												control={
													<Switch
														checked={agentConfig.enable_backchannel || false}
														onChange={(e) =>
															handleInputChange(
																"enable_backchannel",
																e.target.checked
															)
														}
													/>
												}
												label="Enable Backchannel (Beta)"
											/>
										</Grid>
										{agentConfig.enable_backchannel && (
											<>
												<Grid item xs={12}>
													<TextField
														fullWidth
														type="number"
														label="Backchannel Frequency"
														value={agentConfig.backchannel_frequency || 0.8}
														onChange={(e) =>
															handleInputChange(
																"backchannel_frequency",
																parseFloat(e.target.value)
															)
														}
														inputProps={{ step: 0.1, min: 0, max: 1 }}
														helperText="Value ranging from 0 to 1. Default is 0.8"
													/>
												</Grid>
												<Grid item xs={12}>
													<TextField
														fullWidth
														label="Backchannel Words"
														value={
															agentConfig.backchannel_words
																? agentConfig.backchannel_words.join(", ")
																: ""
														}
														onChange={(e) =>
															handleInputChange(
																"backchannel_words",
																e.target.value.split(", ").filter(Boolean)
															)
														}
														helperText="Comma-separated list of words for backchannel"
													/>
												</Grid>
											</>
										)}
										<Grid item xs={12}>
											<FormControl fullWidth>
												<InputLabel>Language (Beta)</InputLabel>
												<Select
													value={agentConfig.language || "en-US"}
													onChange={(e) =>
														handleInputChange("language", e.target.value)
													}
												>
													<MenuItem value="en-US">English (US)</MenuItem>
													<MenuItem value="en-GB">English (UK)</MenuItem>
													<MenuItem value="en-IN">English (India)</MenuItem>
													<MenuItem value="es-419">
														Spanish (Latin America)
													</MenuItem>
													<MenuItem value="es-ES">Spanish (Spain)</MenuItem>
													<MenuItem value="fr-FR">French</MenuItem>
													<MenuItem value="de-DE">German</MenuItem>
													<MenuItem value="hi-IN">Hindi</MenuItem>
													<MenuItem value="ja-JP">Japanese</MenuItem>
													<MenuItem value="pt-PT">
														Portuguese (Portugal)
													</MenuItem>
													<MenuItem value="pt-BR">Portuguese (Brazil)</MenuItem>
													<MenuItem value="ru-RU">Russian</MenuItem>
													<MenuItem value="it-IT">Italian</MenuItem>
													<MenuItem value="ko-KR">Korean</MenuItem>
													<MenuItem value="nl-NL">Dutch</MenuItem>
													<MenuItem value="pl-PL">Polish</MenuItem>
													<MenuItem value="tr-TR">Turkish</MenuItem>
													<MenuItem value="vi-VN">Vietnamese</MenuItem>
													<MenuItem value="ro-RO">Romanian</MenuItem>
													<MenuItem value="mul">
														Multilingual (English and Spanish)
													</MenuItem>
												</Select>
											</FormControl>
										</Grid>
										<Grid item xs={12}>
											<FormControlLabel
												control={
													<Switch
														checked={agentConfig.normalize_for_speech || false}
														onChange={(e) =>
															handleInputChange(
																"normalize_for_speech",
																e.target.checked
															)
														}
													/>
												}
												label="Enable Speech Normalization"
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												fullWidth
												type="number"
												label="End Call After Silence (ms)"
												value={agentConfig.end_call_after_silence_ms || 600000}
												onChange={(e) =>
													handleInputChange(
														"end_call_after_silence_ms",
														parseInt(e.target.value)
													)
												}
												inputProps={{ min: 10000 }}
												helperText="Minimum value: 10,000 ms (10 seconds)"
											/>
										</Grid>
									</Grid>
								</AccordionDetails>
							</Accordion>
						</Grid>
					</Grid>
				</Grid>

				{/* Delete Confirmation Dialog */}
				<Dialog
					open={deleteConfirmOpen}
					onClose={() => setDeleteConfirmOpen(false)}
				>
					<DialogTitle>Confirm Delete</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Are you sure you want to delete this agent? This action cannot be
							undone.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
						<Button onClick={confirmDeleteAgent} color="error">
							Delete
						</Button>
					</DialogActions>
				</Dialog>
			</Grid>
		</Box>
	);
};

export default Agent;
