import config from "config";
import { useQuery } from "@tanstack/react-query";
import useFetchWithAuth from "../useFetchWithAuth";

const useConfigVersionHistory = (name, variation) => {
    const fetchWithAuth = useFetchWithAuth();

    const url = config.backend_url + "/config/version_history/" + name + "?variation=" + variation;

    const fetchConfigVersionHistory = async () => {

        if(!name || !variation) {
            return;
        }
        const res = await fetchWithAuth(url).then(res => res.json());
        return res;
    }

    return useQuery(["configVersionHistory", name], fetchConfigVersionHistory);
}

export default useConfigVersionHistory;