import React from 'react';
import { useOutlet, Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Loader from 'components/Loader/Loader';
import HomeLayout from 'components/HomeLayout/HomeLayout';
import { Helmet } from 'react-helmet';
import useConfigs from 'hooks/api/useConfigs';

const Index = () => {

    const outlet = useOutlet();
    const location = useLocation();
    const configs = useConfigs();

    if (configs.isLoading) {
        return <Loader />;
    }

    let filteredConfigs = configs?.data?.filter(config => !config.includes("::"));

    const navLinks = [
        {
            sectionName: "Configs",
            items: filteredConfigs.map(config => ({
                displayName: config,
                pathname: "/config/" + config
            }))
        }
    ]

    if(location.pathname == "/config/" && filteredConfigs.length > 0) {
        return <Navigate to={"/config/" + filteredConfigs[0]} />
    }
    
    return (
        <>
            <HomeLayout
                navLinks={navLinks}
            >
                {outlet}
            </HomeLayout>
        </>
    );
}


export default Index;