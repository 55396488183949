import config from "config";
import { useQuery } from "@tanstack/react-query";
import useFetchWithAuth from "../useFetchWithAuth";

const useConfigs = () => {
    const fetchWithAuth = useFetchWithAuth();

    const fetchConfigs = async () => {

        const url = config.backend_url + "/config/";
        const res = await fetchWithAuth(url).then(res => res.json());
        return res;
    }

    return useQuery(["configs"], fetchConfigs);
}
  
export default useConfigs;