import { useEffect, useState } from "react";
import usePromptTemplate from "hooks/api/usePromptTemplate";
import useSavedQueryResults from "hooks/api/useSavedQueryResults";
import { useParams } from "react-router-dom";
import Loader from "components/Loader/Loader";
import { Box, Paper, Modal, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { toast } from "react-toastify";

const SavedQueries = () => {
	const params = useParams();
	const { savedQueryName } = params;
	const savedQueryResults = useSavedQueryResults(savedQueryName);

	const [selectedCellData, setSelectedCellData] = useState(null);
	const [selectedCellColumn, setSelectedCellColumn] = useState(null);

	useEffect(() => {
		savedQueryResults.refetch();
	}, [savedQueryName]);

	if (savedQueryResults.isLoading || savedQueryResults.isFetching) {
		return <Loader />;
	}

	// render savedQueryResults.data as a table using MUI DataGrid
	// the first row of savedQueryResults.data is the column names

	const columns = savedQueryResults.data[0].map((column, index) => ({
		field: `col${index + 1}`,
		headerName: column,
		width: 150,
	}));

	const rows = savedQueryResults.data.slice(1).map((row, index) => ({
		id: index,
		...row.reduce((acc, cur, index) => {
			acc[`col${index + 1}`] = cur;
			return acc;
		}, {}),
	}));

	const handleCellClick = (params, event, details) => {
		setSelectedCellData(params.value);
		setSelectedCellColumn(
			columns.find((column) => column.field === params.field).headerName
		);
	};

	const handleCloseModal = () => {
		setSelectedCellData(null);
		setSelectedCellColumn(null);
	};

	const handleCopyToClipboard = () => {
		navigator.clipboard.writeText(selectedCellData);
		toast.success("Copied to clipboard!");
	};

	const prettifyJson = (data) => {
		try {
			const parsedData = JSON.parse(data);
			return JSON.stringify(parsedData, null, 2);
		} catch (error) {
			return data;
		}
	};

	return (
		<Box sx={{ mt: 5, width: "calc(100% - 440px)" }}>
			<DataGrid
				sx={{ width: "-webkit-fill-available", m: 3 }}
				rows={rows}
				columns={columns}
				onCellClick={handleCellClick}
			/>
			<Modal
				open={selectedCellData !== null}
				onClose={handleCloseModal}
				sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
			>
				<Box
					sx={{
						bgcolor: "background.paper",
						boxShadow: 24,
						p: 4,
						maxHeight: "80vh",
						overflowY: "auto",
						width: "80vw",
						maxWidth: "1200px",
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							mb: 2,
						}}
					>
						<h5>{selectedCellColumn}</h5>
						<Button variant="contained" onClick={handleCopyToClipboard}>
							Copy to Clipboard
						</Button>
					</Box>
					<Box sx={{ wordWrap: "break-word" }}>
						{typeof selectedCellData === "string" ? (
							<pre>{prettifyJson(selectedCellData)}</pre>
						) : (
							<p>{selectedCellData}</p>
						)}
					</Box>
				</Box>
			</Modal>
		</Box>
	);
};

export default SavedQueries;
