import config from "config";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useFetchWithAuth from "../useFetchWithAuth";


const useUpdateExperiment = (name) => {

    const fetchWithAuth = useFetchWithAuth();
    const queryClient = useQueryClient()

    const cacheKey = ["experiment", name];

    const updateExperiment = async (experiment) => {
        const url = config.backend_url + "/internal_experiments/" + name;
        
        let payload = {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(experiment)
        }
        const res = await fetchWithAuth(url, payload).then(res => res.json());
        return res;
    }

    return useMutation({
        mutationFn: updateExperiment,
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: [ "experiment" ] })
        },
    })
}

export default useUpdateExperiment;