import config from "config";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useFetchWithAuth from "../useFetchWithAuth";

const useUpdateConfig = (name, variation = "default") => {
	const fetchWithAuth = useFetchWithAuth();
	const queryClient = useQueryClient();

	const cacheKey = ["config", name, variation];

	const updateFormData = async (formData) => {
		const url =
			config.backend_url + "/config/" + name + "?variation=" + variation;

		let payload = {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(formData),
		};
		const res = await fetchWithAuth(url, payload).then((res) => res.json());
		return res;
	};

	return useMutation({
		mutationFn: updateFormData,
		onMutate: async (newConfig) => {
			await queryClient.cancelQueries({ queryKey: cacheKey });
			const previousFormData = queryClient.getQueryData(cacheKey);

			queryClient.setQueryData(cacheKey, (old) => ({
				...old,
				...newConfig,
			}));

			return { previousFormData };
		},

		onError: (err, newConfig, context) => {
			queryClient.setQueryData(cacheKey, context.previousFormData);
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: cacheKey });
		},
	});
};

export default useUpdateConfig;
