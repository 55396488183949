import config from "config";
import { useQuery } from "@tanstack/react-query";
import useFetchWithAuth from "../useFetchWithAuth";

const useExperiments = () => {
    const fetchWithAuth = useFetchWithAuth();

    const fetchExperiments = async () => {

        const url = config.backend_url + "/internal_experiments/";
        const res = await fetchWithAuth(url).then(res => res.json());
        return res;
    }

    return useQuery(["experiments"], fetchExperiments);
}
  
export default useExperiments;