const config = {
  production: {
    backend_url: "https://api.metaforms.ai/www",
  },
  qa: {
    backend_url: "https://api.qa.metaforms.ai/www",
    production_backend_url: "https://api.metaforms.ai/www",
  },
  development: {
    backend_url: "https://api.dev.metaforms.ai/www",
    production_backend_url: "https://api.metaforms.ai/www",
  },
  local: {
    backend_url: "http://localhost:12000/www",
  },
};

let environment = localStorage.getItem("currentEnvironment") ?? "production";

export default config[environment];
