export const encodeEmail = (email) => {
    let hash = 0;

    for (let i = 0; i < email.length; i++) {
        hash = (hash << 5) - hash + email.charCodeAt(i);
        hash = hash & hash;
    }

    const encoded = Math.abs(hash).toString(36).substring(0, 8);
    return encoded;
}
