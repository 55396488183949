import Loader from "components/Loader/Loader";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import Experiment from "./Experiment";

import { useState } from "react";
import useExperiments from "hooks/api/useExperiments";
import { ExpandMore } from "@mui/icons-material";

const NEW_EXPERIMENT = {
  name: "",
  status: "draft",
  description: "",
  objective: "",
  scope: "system",
  result_query: "",
  groups: [
    {
      id: (Math.random() + 1).toString(36).substring(7),
      name: "Control Group",
      weight: 90,
      treatment_defenition: {
        type: "use_config_variation",
        variation_name: "default",
      },
    },
    {
      id: (Math.random() + 1).toString(36).substring(7),
      name: "Treatment Group",
      weight: 10,
      treatment_defenition: {
        type: "use_config_variation",
        variation_name: "",
      },
    },
  ],
};

const Experiments = () => {
  const experiments = useExperiments();

  const [showCreateExperimentDialog, setCreateExperimentDialog] =
    useState(false);
  const [newExperiment, setNewExperiment] = useState();

  const handleNewExperimentClick = () => {
    setNewExperiment(NEW_EXPERIMENT);
    setCreateExperimentDialog(true);
  };

  if (experiments.isLoading) {
    return <Loader />;
  }

  if (experiments.error) {
    return (
      <Typography>Unable to fetch experiments. Please try again.</Typography>
    );
  }

  const renderExperiment = (_experiment) => (
    <Accordion variant="outlined">
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography>{_experiment.name}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Experiment isNewExperiment={false} experiment={_experiment} />
      </AccordionDetails>
    </Accordion>
  );

  return (
    <>
      <Box sx={{ mt: 5, px: 4 }}>
        <Box
          display="flex"
          sx={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Typography variant="h6">Experiments</Typography>
          <Button variant="contained" onClick={handleNewExperimentClick}>
            New Experiment
          </Button>
        </Box>

        <Divider sx={{ mt: 2, mb: 5 }} />

        <Typography sx={{ mb: 1 }}>Live</Typography>
        {experiments?.data
          ?.filter((e) => e.status == "live")
          .map((e) => renderExperiment(e))}

        <Divider sx={{ my: 5 }} />

        <Typography sx={{ mb: 1 }}>Completed</Typography>
        {experiments?.data
          ?.filter((e) => e.status == "completed")
          .map((e) => renderExperiment(e))}
      </Box>

      <Dialog
        open={showCreateExperimentDialog}
        onClose={() => setCreateExperimentDialog(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Create Experiment</DialogTitle>
        <DialogContent>
          <Experiment
            isNewExperiment={true}
            experiment={newExperiment}
            handleClose={() => setCreateExperimentDialog(false)}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Experiments;
