import config from "config";
import { useQuery } from "@tanstack/react-query";
import useFetchWithAuth from "../useFetchWithAuth";

const usePromptTemplateTestResults = (name, version) => {
    const fetchWithAuth = useFetchWithAuth();

    const url = config.backend_url + "/prompt_templates/results/" + name + "?prompt_template_version=" + version;

    const fetchPromptTemplateTestResults = async () => {

        if(!name || !version) {
            return;
        }
        const res = await fetchWithAuth(url).then(res => res.json());
        return res;
    }

    return useQuery(["promptTemplateTestResults", name, version], fetchPromptTemplateTestResults);
}

export default usePromptTemplateTestResults;