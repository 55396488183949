import config from "config";
import { useQuery } from "@tanstack/react-query";
import useFetchWithAuth from "../useFetchWithAuth";

const useConfig = (name, variation="default") => {
    const fetchWithAuth = useFetchWithAuth();

    const url = config.backend_url + "/config/" + name + "?variation=" + variation;

    const fetchConfig = async () => {

        if(!name || !variation) {
            return;
        }
        const res = await fetchWithAuth(url).then(res => res.json());
        return res;
    }

    return useQuery(["config", name], fetchConfig);
}

export default useConfig;