import { useEffect, useState } from 'react';
import usePromptTemplate from "hooks/api/usePromptTemplate";
import useUpdatepromptTemplate from 'hooks/api/useUpdatePromptTemplate';
import usePromptTemplateVersionHistory from 'hooks/api/usePromptTemplateVersionHistory';
import { useParams } from "react-router-dom";
import Loader from 'components/Loader/Loader';
import { Alert, Box, Button, CircularProgress, Container, Dialog, FormControl, InputLabel, MenuItem, Select, Tab, Tabs, TextField, Typography } from '@mui/material';
import JSONEditor from 'components/JsonEditor/JsonEditor';
import { toast } from 'react-toastify';
import { DataGrid } from '@mui/x-data-grid';
import usePromptTemplateTestResults from 'hooks/api/usePromptTemplateTestResults';
import { TabPanel } from '@mui/lab';
import useTriggerPromptTemplateTests from 'hooks/api/useTriggerPromptTemplateTests';

const PromptTemplateTestResult = ({ promptTemplateName, promptTemplateVersion }) => {

    const [open, setOpen] = useState(false);
    const [tab, setTab] = useState(0);
    const promptTemplateTestResult = usePromptTemplateTestResults(promptTemplateName, promptTemplateVersion);
    const triggerPromptTemplateTests = useTriggerPromptTemplateTests(promptTemplateName, promptTemplateVersion);

    const rerunTest = () => {
        triggerPromptTemplateTests.mutateAsync()
            .then(() => {
                toast.success("Test triggered successfully");
                promptTemplateTestResult.refetch();
            }).catch(() => {
                toast.error("Oops! Something went wrong. Please try again");
            });
    }

    const downloadCsv = (data, filename) => {
        const csvData = new Blob([data], { type: 'text/csv;charset=utf-8;' });
        const csvUrl = URL.createObjectURL(csvData);
        const link = document.createElement('a');
        link.href = csvUrl;
        link.setAttribute('download', filename);
        link.click();
    }

    if (promptTemplateTestResult.isLoading) {
        return "Fetching results...";
    }

    if (promptTemplateTestResult.isError) {
        return (
            <>
                <Typography>Error fetching results</Typography>
                <Button onClick={promptTemplateTestResult.refetch}>Retry</Button>
            </>
        );
    }

    if (promptTemplateTestResult.data?.testing_in_progress) {
        promptTemplateTestResult.refetch();
        return <CircularProgress />
    }

    if (!promptTemplateTestResult.data?.aggregate_metrics || !promptTemplateTestResult.data?.raw_results) {
        return (
            <>
                <Typography>No test results</Typography>
                <Button onClick={rerunTest}>
                    Re-Run Test
                </Button>
            </>
        )
    }

    const downloadAggregateMetrics = () => {
        const csv = promptTemplateTestResult.data?.aggregate_metrics?.map(row => row.map(x => x.replace(/\n/g, "\\n")).join(',')).join('\n');
        downloadCsv(csv, 'aggregate_metrics.csv');
    }

    const downloadRawResults = () => {
        const csv = promptTemplateTestResult.data?.raw_results?.map(row => row.map(x => x.replace(/\n/g, "\\n")).join(',')).join('\n');
        downloadCsv(csv, 'raw_results.csv');
    }

    return (
        <>
            <Button onClick={() => setOpen(true)}>
                View Results
            </Button>

            <Button onClick={rerunTest}>
                Re-Run Test
            </Button>

            <Dialog open={open} onClose={() => setOpen(false)} fullScreen>
                <Tabs value={tab} onChange={(e, t) => setTab(t)}>
                    <Tab label="Aggregate Metrics" />
                    <Tab label="Raw Results" />
                </Tabs>

                {tab == 0 && (
                    <>
                        <Button onClick={downloadAggregateMetrics}>Download CSV</Button>
                        <DataGrid
                            columns={promptTemplateTestResult.data?.aggregate_metrics?.[0]?.map((col, index) => ({ field: index, headerName: col, flex: 1 }))}
                            rows={promptTemplateTestResult.data?.aggregate_metrics?.slice(1)?.map((row, index) => ({ ...row, id: index }))}
                        />
                    </>
                )}

                {tab == 1 && (
                    <>
                        <Button onClick={downloadRawResults}>Download CSV</Button>
                        <DataGrid
                            columns={promptTemplateTestResult.data?.raw_results?.[0]?.map((col, index) => ({ field: index, headerName: col, flex: 1 }))}
                            rows={promptTemplateTestResult.data?.raw_results?.slice(1)?.map((row, index) => ({ ...row, id: index }))}
                        />
                    </>
                )}

            </Dialog>
        </>
    )
}


const PromptTemplate = () => {

    const params = useParams();
    const { promptTemplateName } = params;
    const promptTemplate = usePromptTemplate(promptTemplateName);

    const updatepromptTemplate = useUpdatepromptTemplate(promptTemplateName);
    const updatepromptTemplateInProd = useUpdatepromptTemplate(promptTemplateName, true);
    const promptTemplateVersionHistory = usePromptTemplateVersionHistory(promptTemplateName);

    const [promptTemplateCopy, setPromptTemplateCopy] = useState(null);
    const [commitMessage, setCommitMessage] = useState("");
    const [tab, setTab] = useState(0);

    useEffect(() => {
        if (promptTemplate.data?.prompt) {
            setPromptTemplateCopy(promptTemplate.data?.prompt);
        }
    }, [promptTemplate.data]);

    const handleSaveClick = async () => {
        await updatepromptTemplate.mutateAsync({
            prompt: promptTemplateCopy,
            commit_message: commitMessage
        }).then(() => {
            toast.success("Prompt template updated successfully!");
            setCommitMessage("");
            promptTemplateVersionHistory.refetch();
        }).catch((err) => {
            toast.error("Oops! Something went wrong. Please try again");
        })
    }

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(JSON.stringify(promptTemplateCopy.prompt));
        toast.success("Copied prompt to clipboard");
    }

    const handlePasteFromClipboard = async () => {
        const clipboardText = await navigator.clipboard.readText();
        try {
            const parsedClipboardText = JSON.parse(clipboardText);
            if (Array.isArray(parsedClipboardText)) {
                setPromptTemplateCopy({
                    ...promptTemplateCopy,
                    prompt: parsedClipboardText
                })
            }
        } catch (err) {
            console.log(err);
            toast.error("Unable to parse prompt from clipboard. Please make sure it is a valid JSON array");
        }
        toast.success("Pasted prompt from clipboard");
    }

    const migrateToProductionClick = async () => {
        if(confirm("Have you tested this prompt in development?")) {
            migrateToProductionConfirm();
        } 
    }

    const migrateToProductionConfirm = async () => {
        await updatepromptTemplateInProd.mutateAsync({
            prompt: promptTemplateCopy,
            commit_message: `Migrated from development version ${promptTemplate.data.version} to production`
        }).then(() => {
            toast.success("Prompt template migrated to production successfully!");
        }).catch((err) => {
            toast.error("Oops! Something went wrong. Please try again");
        })
    }

    if (promptTemplate.isLoading) {
        return <Loader />
    }

    return (
        <Box sx={{ mt: 5, px: 4 }}>

            <Box display="flex" sx={{ alignItems: "center" }}>
                <Tabs value={tab} onChange={(e, t) => setTab(t)} sx={{ mb: 2 }}>
                    <Tab label="Configuration" />
                    <Tab label="Version History" />
                </Tabs>
                <Box sx={{ flexGrow: 1 }} />
                <Typography variant='caption'>
                    Version: {promptTemplate.data.version}
                </Typography>
            </Box>

            {tab == 0 && (
                <>
                    <Box sx={{ gap: 3, width: "-webkit-fill-available" }}>
                        <Box sx={{ display: "flex", width: "-webkit-fill-available" }}>
                            <Box sx={{ flexGrow: 1 }} />
                            {localStorage.getItem("currentEnvironment") == "production" ? (
                                <Alert color="error" sx={{ width: "-webkit-fill-available", mb: 2 }}>
                                    Updates are not allowed in production. Update in development, test it, and then click on "Migrate to Production".
                                </Alert>
                            ) : (
                                <>
                                    <Button onClick={handlePasteFromClipboard}>
                                        Paste From Clipboard
                                    </Button>
                                </>
                            )}
                            <Button onClick={handleCopyToClipboard} sx={{ width: 200 }}>
                                Copy To Clipboard
                            </Button>

                            {localStorage.getItem("currentEnvironment") == "development" && (
                                <Button
                                    variant="contained"
                                    onClick={migrateToProductionClick}
                                >
                                    Migrate to Production
                                </Button>
                            )}
                        </Box>
                        {promptTemplateCopy?.type == "json" && (
                            <>
                                {promptTemplateCopy.prompt.map((promptItem, index) => (
                                    <Box sx={{ display: "flex", gap: 2, my: 2 }}>
                                        <FormControl sx={{ width: "150px" }}>
                                            <InputLabel>Role</InputLabel>
                                            <Select
                                                value={promptItem.role}
                                                label="Role"
                                                disabled={localStorage.getItem("currentEnvironment") == "production"}
                                                onChange={(e) => {
                                                    setPromptTemplateCopy({
                                                        ...promptTemplateCopy,
                                                        prompt: [
                                                            ...promptTemplateCopy.prompt.slice(0, index),
                                                            {
                                                                ...promptTemplateCopy.prompt[index],
                                                                role: e.target.value
                                                            },
                                                            ...promptTemplateCopy.prompt.slice(index + 1)
                                                        ]
                                                    })
                                                }}
                                            >
                                                <MenuItem value="system">System</MenuItem>
                                                <MenuItem value="user">User</MenuItem>
                                                <MenuItem value="assistant">Assistant</MenuItem>
                                            </Select>
                                        </FormControl>

                                        <TextField
                                            multiline
                                            fullWidth
                                            disabled={localStorage.getItem("currentEnvironment") == "production"}
                                            value={promptItem.content.replaceAll("\\n", "\n")}
                                            onChange={(e) => {
                                                setPromptTemplateCopy({
                                                    ...promptTemplateCopy,
                                                    prompt: [
                                                        ...promptTemplateCopy.prompt.slice(0, index),
                                                        {
                                                            ...promptTemplateCopy.prompt[index],
                                                            content: e.target.value
                                                        },
                                                        ...promptTemplateCopy.prompt.slice(index + 1)
                                                    ]
                                                })
                                            }}
                                        />

                                        {localStorage.getItem("currentEnvironment") != "production" && (
                                            <Button
                                                color="error"
                                                onClick={() => {
                                                    setPromptTemplateCopy({
                                                        ...promptTemplateCopy,
                                                        prompt: [
                                                            ...promptTemplateCopy.prompt.slice(0, index),
                                                            ...promptTemplateCopy.prompt.slice(index + 1)
                                                        ]
                                                    })
                                                }}
                                            >
                                                Delete
                                            </Button>
                                        )}
                                    </Box>
                                ))}

                                {localStorage.getItem("currentEnvironment") != "production" && (
                                    <Button
                                        onClick={() => {
                                            setPromptTemplateCopy({
                                                ...promptTemplateCopy,
                                                prompt: [
                                                    ...promptTemplateCopy.prompt,
                                                    {
                                                        role: "assistant",
                                                        content: ""
                                                    }
                                                ]
                                            })
                                        }}
                                    >
                                        Add Message
                                    </Button>
                                )}
                            </>
                        )}

                        {promptTemplateCopy?.type == "text" && (
                            <TextField
                                value={promptItem.content}
                            />
                        )}
                    </Box>

                    <Box sx={{ display: "flex", my: 3, gap: 2 }}>
                        {localStorage.getItem("currentEnvironment") != "production" && (
                            <>
                                <TextField
                                    placeholder='Commit message'
                                    value={commitMessage}
                                    onChange={(e) => setCommitMessage(e.target.value)}
                                    fullWidth
                                />
                                <Button
                                    sx={{ width: 300 }}
                                    variant='contained'
                                    onClick={() => handleSaveClick()}
                                    disabled={updatepromptTemplate.isLoading}
                                >
                                    {updatepromptTemplate.isLoading ? "Saving..." : "Save as new version"}
                                </Button>
                            </>

                        )}
                    </Box>
                </>
            )}

            {tab == 1 && (
                <>
                    <DataGrid
                        columns={[
                            { field: 'timestamp', headerName: 'Timestamp', width: 200, valueGetter: (params) => (new Date(params.value)).toLocaleString() },
                            { field: 'version', headerName: 'Version', width: 100 },
                            { field: 'commit_message', headerName: 'Commit Message', flex: 1 },
                            { field: 'commit_by', headerName: 'Commit By', width: 150 },
                            {
                                headerName: "Test Results",
                                width: 300,
                                renderCell: (params) => {
                                    return <PromptTemplateTestResult promptTemplateName={promptTemplateName} promptTemplateVersion={params.row.version} />
                                }
                            }
                        ]}
                        rows={promptTemplateVersionHistory.data?.map(r => ({ ...r, id: r.timestamp })) ?? []}
                        onRowClick={() => { }}
                    />
                </>
            )}
        </Box >
    )
}

export default PromptTemplate;