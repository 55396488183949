import React from "react";
import { useOutlet, Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Loader from "components/Loader/Loader";
import HomeLayout from "components/HomeLayout/HomeLayout";
import useConfig from "hooks/api/useConfig";

const Agents_Index = () => {
	const outlet = useOutlet();
	const location = useLocation();
	const config = useConfig("retell_agents");

	if (config.isLoading) {
		return <Loader />;
	}

	const agents = config.data?.data || [];

	const navLinks = [
		{
			sectionName: "Agents",
			items: agents.map((agent) => ({
				displayName: agent.agent_name,
				pathname: "/agents/" + agent.agent_name,
			})),
		},
	];

	if (location.pathname === "/agents/" && agents.length > 0) {
		return <Navigate to={"/agents/" + agents[0].agent_name} />;
	}

	return <HomeLayout navLinks={navLinks}>{outlet}</HomeLayout>;
};

export default Agents_Index;
