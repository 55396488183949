import config from "config";
import { useQuery } from "@tanstack/react-query";
import useFetchWithAuth from "../useFetchWithAuth";

const usePromptTemplates = () => {
    const fetchWithAuth = useFetchWithAuth();

    const fetchConfigs = async () => {

        const url = config.backend_url + "/prompt_templates/";
        const res = await fetchWithAuth(url).then(res => res.json());
        return res;
    }

    return useQuery(["prompt_templates"], fetchConfigs);
}
  
export default usePromptTemplates;