import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FormHelperText, Link } from "@mui/material";
import { Turnstile } from "@marsidev/react-turnstile";

import AuthLayout from "../../components/Auth/AuthLayout";
import AuthButton from "../../components/Auth/AuthButton";
import AuthInput from "../../components/Auth/AuthInput";

import { supabase } from "../../supbaseClient";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [captchaToken, setCaptchaToken] = useState("");

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
      options: { captchaToken },
    });
    if (error) {
      alert(error.message);
    } else {
      navigate("/", { replace: true });
    }
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>Workhack | Login</title>
      </Helmet>
      <AuthLayout title="Login" onSubmit={handleLogin}>
        <AuthInput
          type="email"
          placeholder="Email"
          autoComplete="email"
          required={true}
          value={email}
          disabled={loading}
          onChange={(e) => setEmail(e.target.value)}
        />
        <AuthInput
          type="password"
          placeholder="Password"
          autoComplete="current-password"
          required={true}
          value={password}
          disabled={loading}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="flex justify-center">
          <Turnstile
            siteKey={process.env.REACT_APP_TURNSTILE_SITE_KEY || ""}
            onSuccess={(token) => {
              setCaptchaToken(token);
            }}
          />
        </div>
        <AuthButton disabled={loading}>Login</AuthButton>
      </AuthLayout>
    </>
  );
};

export default Login;
