import { Close } from "@mui/icons-material";
import {
	Alert,
	Box,
	Button,
	Divider,
	Grid,
	IconButton,
	LinearProgress,
	Link,
	Paper,
	TextField,
	Typography,
} from "@mui/material";
import useFetchWithAuth from "hooks/useFetchWithAuth";
import { useState } from "react";
import config from "config";
import { toast } from "react-toastify";
import Papa from "papaparse";

const GenerateTemplates = () => {
	const fetchWithAuth = useFetchWithAuth();

	const [keywordGroups, setKeywordGroups] = useState([]);
	const [results, setResults] = useState([]);
	const [status, setStatus] = useState("not_started");
	const [progress, setProgress] = useState(0);
	const [errorPercentage, setErrorPercentage] = useState(0);

	const downloadSample = () => {
		let data = [];
		data.push({
			"Keyword Groups": "Lead gen forms, lead gen, lead generation",
		});
		data.push({
			"Keyword Groups": "Book a demo forms, book a demo, demo",
		});
		data.push({
			"Keyword Groups": "Contact us forms, contact us, contact",
		});
		data.push({
			"Keyword Groups": "employee feedback forms, team feedback form",
		});

		let csv = Papa.unparse(data);

		let a = document.createElement("a");
		a.href = URL.createObjectURL(new Blob([csv], { type: "text/csv" }));
		a.setAttribute("download", "keyword_groups_sample.csv");
		document.body.appendChild(a);
		a.click();
	};

	const handleFileSelected = (e) => {
		const file = e.target.files[0];
		const reader = new FileReader();
		reader.onload = async (e) => {
			const text = e.target.result;
			const keywordGroups = text.split("\n");
			setKeywordGroups(keywordGroups.slice(1));
		};
		reader.readAsText(file);
	};

	const handleStart = async () => {
		setStatus("in_progress");

		const allPromises = keywordGroups.map((keywordGroup, i) => {
			return fetchWithAuth(
				`${config.backend_url}/llm_components/generate?component_name=content_tools&function_name=generate_cms_templates`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						keyword_groups: [keywordGroup],
					}),
				}
			)
				.then(async (response) => {
					if (response.status != 200) {
						toast.error(
							`Failed to generate templates for batch starting at index: ${i}`
						);
					}

					let data = await response.json();
					console.log(data);

					if (Array.isArray(data)) {
						setResults((results) => [...results, ...data]);
						setProgress((results.length / keywordGroups.length) * 100);
					} else {
						setErrorPercentage((i / keywordGroups.length) * 100);
					}
				})
				.catch((error) => {
					console.error(`Error at index ${i}:`, error);
				});
		});

		for (let i = 0; i < allPromises.length; i += 3) {
			await Promise.allSettled(allPromises.slice(i, i + 3));
		}

		setStatus("completed");
	};
	const exportCsv = () => {
		let data = [];
		results.forEach((result) => {
			result["variations"].forEach((variation) => {
				let tags = Array.isArray(variation["Tags"])
					? variation["Tags"].join(", ")
					: variation["Tags"];
				data.push({
					"Keyword Group": result["Keyword Group"],
					Slug: variation["Title"].toLowerCase().replace(/ /g, "-"),
					Title: variation["Title"],
					Tags: tags,
					"Short Description": variation["Short Description"],
					"Long Description": variation["Long Description"],
				});
			});
		});

		let csv = Papa.unparse(data);

		let a = document.createElement("a");
		a.href = URL.createObjectURL(new Blob([csv], { type: "text/csv" }));
		a.setAttribute("download", "generated_templates.csv");
		document.body.appendChild(a);
		a.click();
	};

	return (
		<Box sx={{ p: 3 }}>
			<Typography variant="h6">Generate form templates from keyword</Typography>

			{keywordGroups.length == 0 && (
				<>
					<Typography>
						Upload keyword group list CSV{" "}
						<Link sx={{ cursor: "pointer" }} onClick={downloadSample}>
							Download Sample
						</Link>
					</Typography>

					<TextField
						type="file"
						variant="outlined"
						fullWidth
						sx={{ mt: 1 }}
						onChange={handleFileSelected}
					/>
				</>
			)}

			{keywordGroups.length > 0 && (
				<Paper sx={{ p: 2, my: 2 }} variant="outlined">
					<Typography variant="h6">
						Total Keyword Groups: {keywordGroups.length}
					</Typography>

					{status == "not_started" && (
						<Button onClick={handleStart}>Start</Button>
					)}

					{(status == "in_progress" || status == "completed") && (
						<Box>
							<Typography sx={{ flexGrow: 1 }}>
								Progress: {progress}%
							</Typography>

							<Typography sx={{ flexGrow: 1 }}>
								Error Percentage: {errorPercentage}%
							</Typography>

							{status == "in_progress" && <LinearProgress value={progress} />}
						</Box>
					)}
				</Paper>
			)}

			{results.length > 0 && (
				<>
					<Typography variant="h6" sx={{ mb: 3 }}>
						Results{" "}
						<Link sx={{ cursor: "pointer" }} onClick={exportCsv}>
							Export
						</Link>
					</Typography>
					{results.map((result, index) => (
						<>
							<Typography variant="body2">
								<b>{result["Keyword Group"]}</b>
							</Typography>
							<Box display="flex" sx={{ gap: 2, mb: 3 }}>
								{result["variations"].map((variation) => (
									<Paper sx={{ p: 2, flexGrow: 1 }} variant="outlined">
										<Typography variant="overline">Title</Typography>
										<Typography>{variation["Title"]}</Typography>
										<Divider />
										<Typography variant="overline">Tags</Typography>
										<Typography>{variation["Tags"]}</Typography>
										<Divider />
										<Typography variant="overline">
											Short Description
										</Typography>
										<Typography>{variation["Short Description"]}</Typography>
										<Divider />
										<Typography variant="overline">Long Description</Typography>
										<Typography>{variation["Long Description"]}</Typography>
									</Paper>
								))}
							</Box>
						</>
					))}
				</>
			)}
		</Box>
	);
};

export default GenerateTemplates;
