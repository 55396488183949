import config from "config";
import { useQuery } from "@tanstack/react-query";
import useFetchWithAuth from "../useFetchWithAuth";

const usePromptTemplateVersionHistory = (name) => {
    const fetchWithAuth = useFetchWithAuth();

    const url = config.backend_url + "/prompt_templates/version_history/" + name;

    const fetchPromptTemplateVersionHistory = async () => {

        if(!name) {
            return;
        }
        const res = await fetchWithAuth(url).then(res => res.json());
        return res;
    }

    return useQuery(["promptTemplateVersionHistory", name], fetchPromptTemplateVersionHistory);
}

export default usePromptTemplateVersionHistory;