import { Button } from "@mui/material";

export default function AuthButton(props) {
  return (
    <Button
      {...props}
      type="submit"
      variant="contained"
      sx={{
        bgcolor: "#9D63CD",
        marginBottom: 2,
        borderRadius: 2,
      }}
    />
  );
}
