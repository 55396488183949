import React from "react";
import {
	createBrowserRouter,
	createRoutesFromElements,
	Route,
} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Login from "pages/Login/Login";

import Configs_Index from "pages/Configs/Configs_Index";
import Config from "pages/Configs/Config/Config";

import PromptTemplates_Index from "pages/PromptTemplates/PromptTemplates_Index";
import PromptTemplates from "pages/PromptTemplates/PromptTemplates/PromptTemplates";

import SavedQueries_Index from "pages/SavedQueries/SavedQueries_Index";
import SavedQueries from "pages/SavedQueries/SavedQueries/SavedQueries";

import Experiments_Index from "pages/Experiments/Experiments_Index";
import Experiments from "pages/Experiments/Experiments/Experiments";

import Content_Index from "pages/Content/Content_Index";
import Templates from "pages/Content/Templates/Templates";
import Agents_Index from "pages/Agents/Agents_Index";
import Agent from "pages/Agents/Agent/Agent";

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route>
			<Route path="/" element={<ProtectedRoute />}>
				<Route path="/config/" element={<Configs_Index />}>
					<Route path=":configName" element={<Config />} />
				</Route>

				<Route path="/prompt_templates/" element={<PromptTemplates_Index />}>
					<Route path=":promptTemplateName" element={<PromptTemplates />} />
				</Route>

				<Route path="/agents/" element={<Agents_Index />}>
					<Route path=":agentName" element={<Agent />} />
				</Route>

				<Route path="/saved_queries/" element={<SavedQueries_Index />}>
					<Route path=":savedQueryName" element={<SavedQueries />} />
				</Route>

				<Route path="/experiments/" element={<Experiments_Index />}>
					<Route path="" element={<Experiments />} />
				</Route>

				<Route path="/content/" element={<Content_Index />}>
					<Route path="templates" element={<Templates />} />
				</Route>
			</Route>

			<Route path="login" element={<Login />} />
		</Route>
	)
);

export default router;
