import { useMutation, useQueryClient } from "@tanstack/react-query";
import useFetchWithAuth from "../useFetchWithAuth";
import config from "config";


const useTriggerPromptTemplateTests = (name, version) => {

    const fetchWithAuth = useFetchWithAuth();
    const queryClient = useQueryClient()

    const update = async () => {
        const url = config.backend_url + "/prompt_templates/run_tests/" + name + "?prompt_template_version=" + version;
        
        let payload = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({})
        }
        const res = await fetchWithAuth(url, payload).then(res => res.json());
        return res;
    }

    return useMutation({
        mutationFn: update,
    })
}

export default useTriggerPromptTemplateTests;