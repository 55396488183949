import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";
import Paper from "@mui/material/Paper";
import { ListSubheader } from "@mui/material";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { useNavigate, useLocation } from "react-router-dom";
import { ExpandMore } from "@mui/icons-material";

import useSession from "../../hooks/useSession";
import { supabase } from "../../supbaseClient";

const drawerWidth = 440;

const environments = ["local", "development", "qa", "production"];

const CONFIG_WHITELIST = [
	"arjun@workhack.ai",
	"arjun@metaforms.ai",
	"amey@workhack.ai",
	"amey@metaforms.ai",
	"prateek@workhack.ai",
	"shravan@workhack.ai",
	"tarun@workhack.ai",
	"siddish@workhack.ai",
	"mohan@workhack.ai",
	"smit@workhack.ai",
];

const PROMPT_TEMPLATES_WHITELIST = [
	"arjun@metaforms.ai",
	"arjun@workhack.ai",
	"siddish@workhack.ai",
	"mohan@workhack.ai",
	"smit@workhack.ai",
	"akshat@workhack.ai",
	"amey@workhack.ai",
	"amey@metaforms.ai",
	"prateek@workhack.ai",
	"tarun@workhack.ai",
];

const HomeLayout = ({ navLinks, children }) => {
	const session = useSession();
	const navigate = useNavigate();
	const location = useLocation();

	const selectedEnvironment = localStorage.getItem("currentEnvironment");

	const handleselectedEnvironmentChange = (environment, popupState) => {
		if (popupState) {
			bindPopover(popupState)?.onClose();
		}

		if (environment != selectedEnvironment) {
			localStorage.setItem("currentEnvironment", environment);
			window.location.reload();
		}
	};

	return (
		<>
			<Box
				sx={{
					backgroundColor: "background.yellowShade",
					height: "100vh",
					display: "flex",
					flexDirection: "row",
				}}
			>
				<Box display="flex" flexDirection="column">
					<Box
						py={3}
						px={3}
						sx={{
							height: "32px",
							backgroundColor: "white",
							boxShadow: "container",
						}}
					>
						<Box display="flex" sx={{ alignItems: "center" }}>
							<Typography variant="h6" height={35}>
								Internal Console
							</Typography>
						</Box>
					</Box>

					<Box sx={{ flexGrow: 1, mt: 1, overflowY: "auto" }}>
						{navLinks.map((section) => (
							<List
								dense
								sx={{ width: drawerWidth }}
								subheader={
									<ListSubheader
										sx={{ background: "#fbfbfb", mt: "1px", mr: "1px" }}
									>
										{section.sectionName}
									</ListSubheader>
								}
							>
								{section.items.map((item) => (
									<ListItem key={item.pathname} py={0}>
										<ListItemButton
											onClick={() => navigate(item.pathname)}
											selected={location.pathname.startsWith(item.pathname)}
											sx={{
												borderRadius: 2,
											}}
										>
											<ListItemText primary={item.displayName} />
										</ListItemButton>
									</ListItem>
								))}
							</List>
						))}
					</Box>
					<Box
						sx={{
							borderRadius: 3,
							border: "dashed 1px",
							borderColor: "grey.400",
							position: "relative",
						}}
						p={2}
						mb={3}
						mx={2}
					>
						<Box
							sx={{
								opacity: 0,
								"&:hover": {
									opacity: 1,
								},
								zIndex: 100,
								borderRadius: "8px",
								position: "absolute",
								background: "#9820481c",
								backdropFilter: "blur(10px)",
								top: 0,
								right: 0,
								bottom: 0,
								left: 0,
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								size="large"
								fullWidth
								onClick={async () => {
									await supabase.auth.signOut();
									navigate("/", { replace: true });
								}}
							>
								Log Out
							</Button>
						</Box>
						<Typography variant="caption">Signed in as</Typography>
						<br></br>
						{session !== "loading" && session !== null ? (
							<Typography>{session.user.email}</Typography>
						) : null}
					</Box>
				</Box>
				<Box sx={{ flex: 1 }}>
					<Box
						sx={{
							flexGrow: 1,
							backgroundColor: "white",
							marginLeft: "0px",
							overflow: "hidden",
							boxShadow: "container",
							overflowY: "auto",
							height: "100vh",
							position: "relative",
						}}
					>
						<Box
							sx={{
								px: 3,
								display: "flex",
								zIndex: 100,
								top: 0,
								left: 0,
								right: 0,
								position: "sticky",
								height: 80,
								alignItems: "center",
								backdropFilter: "blur(10px)",
								backgroundColor: "white",
								boxShadow: "container",
							}}
						>
							<Box display="flex" sx={{ alignItems: "center" }}>
								{CONFIG_WHITELIST.includes(session?.user?.email) && (
									<Button
										onClick={() => (window.location.href = "/config/")}
										sx={
											window.location.pathname.startsWith("/config/")
												? { textDecoration: "underline" }
												: {}
										}
									>
										Config
									</Button>
								)}
								{PROMPT_TEMPLATES_WHITELIST.includes(session?.user?.email) && (
									<Button
										onClick={() =>
											(window.location.href = "/prompt_templates/")
										}
										sx={
											window.location.pathname.startsWith("/prompt_templates/")
												? { textDecoration: "underline" }
												: {}
										}
									>
										Prompt Templates
									</Button>
								)}
								{CONFIG_WHITELIST.includes(session?.user?.email) && (
									<Button
										onClick={() => (window.location.href = "/agents/")}
										sx={
											window.location.pathname.startsWith("/agents/")
												? { textDecoration: "underline" }
												: {}
										}
									>
										Agents
									</Button>
								)}
								<Button
									onClick={() => (window.location.href = "/saved_queries/")}
									sx={
										window.location.pathname.startsWith("/saved_queries/")
											? { textDecoration: "underline" }
											: {}
									}
								>
									Saved Queries
								</Button>

								{false && (
									<Button
										onClick={() => (window.location.href = "/experiments/")}
										sx={
											window.location.pathname.startsWith("/experiments/")
												? { textDecoration: "underline" }
												: {}
										}
									>
										Experiments
									</Button>
								)}

								<Button
									onClick={() => (window.location.href = "/content/")}
									sx={
										window.location.pathname.startsWith("/content/")
											? { textDecoration: "underline" }
											: {}
									}
								>
									Content
								</Button>
							</Box>

							<Box sx={{ flexGrow: 1 }} />

							<PopupState variant="popover" popupId="demo-popup-popover">
								{(popupState) => (
									<>
										<Button autoCapitalize={false}>
											<Box
												display="flex"
												sx={{
													alignItems: "center",
												}}
												{...bindTrigger(popupState)}
											>
												<Typography
													sx={{ fontSize: 15, fontWeight: 600, mr: 1 }}
												>
													{selectedEnvironment}
												</Typography>
												<ExpandMore />
											</Box>
										</Button>

										<Popover
											{...bindPopover(popupState)}
											anchorOrigin={{
												vertical: "bottom",
												horizontal: "center",
											}}
											transformOrigin={{
												vertical: "top",
												horizontal: "center",
											}}
											slotProps={{
												paper: {
													sx: {
														mt: 2,
														boxShadow: "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px",
													},
												},
											}}
										>
											<Paper
												variant="outlined"
												sx={{ minWidth: "180px", maxWidth: "300px" }}
											>
												<List sx={{ width: "-webkit-fill-available" }}>
													{environments.map((environment) => (
														<ListItemButton
															style={{ height: 50 }}
															onClick={() =>
																handleselectedEnvironmentChange(
																	environment,
																	popupState
																)
															}
														>
															{environment}
														</ListItemButton>
													))}
												</List>
											</Paper>
										</Popover>
									</>
								)}
							</PopupState>
						</Box>
						<Box
							sx={{
								width: "-webkit-fill-available",
								pt: "80px",
								height: "-webkit-fill-available",
							}}
						>
							{children}
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default HomeLayout;
