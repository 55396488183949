import React from 'react';
import { useOutlet, Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Loader from 'components/Loader/Loader';
import HomeLayout from 'components/HomeLayout/HomeLayout';
import { Helmet } from 'react-helmet';
import usePromptTemplates from 'hooks/api/usePromptTemplates';

const Index = () => {

    const outlet = useOutlet();
    const location = useLocation();
    const promptTemplates = usePromptTemplates();

    if (promptTemplates?.isLoading) {
        return <Loader />;
    }

    const navLinks = [
        {
            sectionName: "Prompt Templates",
            items: promptTemplates?.data?.map(promptTemplateName => ({
                displayName: promptTemplateName,
                pathname: "/prompt_templates/" + promptTemplateName
            }))
        }
    ]

    if(location.pathname == "/prompt_templates/" && promptTemplates?.data?.length > 0) {
        return <Navigate to={"/prompt_templates/" + promptTemplates?.data?.[0]} />
    }
    
    return (
        <>
            <HomeLayout
                navLinks={navLinks}
            >
                {outlet}
            </HomeLayout>
        </>
    );
}


export default Index;