import React, { useEffect, useRef, useState } from 'react';
import JSONEditor from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.min.css';

import Ajv from 'ajv';

function JsonEditor({
  value,
  onChange,
  mode,
  schema,
  search,
  sortObjectKeys,
  history,
  enableTransform,
  onEditable,
  onNodeClick,
  onNodeCollapse,
  onNodeExpand,
  onTextSelection,
  onValidChange,
}) {
  const containerRef = useRef(null);
  let jsoneditor = null;
  let ajv = null;

  useEffect(() => {
    const options = {
      mode: mode || 'code',
      schema: schema || null,
      search: search || true,
      sortObjectKeys: sortObjectKeys || false,
      history: history || true,
      enableTransform: enableTransform || true,
      onEditable: onEditable || null,
      onNodeClick: onNodeClick || null,
      onNodeCollapse: onNodeCollapse || null,
      onNodeExpand: onNodeExpand || null,
      onTextSelection: onTextSelection || null,
      showErrorTable: true,
      onChange: (() => {
        try {
          const json = jsoneditor.get();
          const isValid = ajv.validate(schema, json);
          onValidChange && onValidChange(isValid);
          onChange(json);
        } catch (err) {
          onValidChange && onValidChange(false);
        }
      })
    };

    // Initialize the Ajv instance
    ajv = new Ajv({
      allErrors: true,
      verbose: true,
      jsonPointers: false,
      $data: true,
    });

    // Initialize the JSONEditor
    jsoneditor = new JSONEditor(containerRef.current, options);

    // Set the initial JSON data
    jsoneditor.set(value);

    return () => {
      if (jsoneditor) {
        jsoneditor.destroy();
      }
    };
  }, [
    mode,
    schema,
    value,
    search,
    sortObjectKeys,
    history,
    enableTransform,
    onEditable,
    onNodeClick,
    onNodeCollapse,
    onNodeExpand,
    onTextSelection,
    onValidChange,
  ]);

  return (
    <div ref={containerRef} style={{ height: '-webkit-fill-available', width: '50%' }} />
  );
}

export default JsonEditor;
