import { Stack, Typography } from "@mui/material";

import WorkhackLogo from "../../assets/images/workhackLogo.svg";

export default function AuthLayout({ title, onSubmit, children }) {
  return (
    <Stack
      sx={{ justifyContent: "center", alignItems: "center", height: "100vh" }}
    >
      <img src={WorkhackLogo} width={200} height={60} />
      <Stack component="form" onSubmit={onSubmit} sx={{ width: 300 }}>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          {title}
        </Typography>
        {children}
      </Stack>
    </Stack>
  );
}
