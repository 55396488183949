import React from "react";
import { Navigate, useOutlet } from "react-router-dom";
import Login from "pages/Login/Login";
import Loader from "components/Loader/Loader";
import useSession from "../hooks/useSession";

const ProtectedRoute = () => {
  const session = useSession();

  const outlet = useOutlet();

  if (session == "loading") {
    return <Loader />;
  }

  if (session && session?.user?.email?.split("@")[1] != "workhack.ai") {
    return (
      <>
        Not authorized.{" "}
        <a href="#" onClick={logout}>
          Click here
        </a>{" "}
        to login with a workhack account.
      </>
    );
  }

  if (location.pathname == "/") {
    return <Navigate to={"/config/"} />;
  }

  if (session) {
    return outlet;
  }

  return <Login />;
};

export default ProtectedRoute;
