import { useEffect, useState } from 'react';
import useConfig from "hooks/api/useConfig";
import useUpdateConfig from 'hooks/api/useUpdateConfig';
import { useParams } from "react-router-dom";
import Loader from 'components/Loader/Loader';
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Tab, Tabs, TextField, Typography } from '@mui/material';
import JSONEditor from 'components/JsonEditor/JsonEditor';
import { toast } from 'react-toastify';
import useConfigVersionHistory from 'hooks/api/useConfigVersionHistory';
import { DataGrid } from '@mui/x-data-grid';
import useConfigVariations from 'hooks/api/useConfigVariations';
import useFetchWithAuth from 'hooks/useFetchWithAuth';
import frontendconfig from "config";



const Config = () => {

    const params = useParams();
    const { configName } = params;
    const fetchWithAuth = useFetchWithAuth();
    const configSchema = useConfig(configName + "::schema");
    const configVariations = useConfigVariations(configName);
    
    const [selectedVariation, setSelectedVariation] = useState("default");
    const config = useConfig(configName, selectedVariation);
    const configVersionHistory = useConfigVersionHistory(configName, selectedVariation);
    const updateConfig = useUpdateConfig(configName, selectedVariation);

    const [configCopy, setConfigCopy] = useState(null);
    const [isConfigValid, setIsConfigValid] = useState(true);
    const [newConfigVariation, setNewConfigVariation] = useState(false);
    const [commitMessage, setCommitMessage] = useState("");
    const [tab, setTab] = useState(0);

    useEffect(() => {
        if (config.data?.data) {
            setConfigCopy(config.data.data);
        }
    }, [config.data]);

    useEffect(() => {
        config.refetch();
        configVersionHistory.refetch();
    }, [selectedVariation]);


    const handleSaveClick = async (saveAsNewVersion = true) => {
        await updateConfig.mutateAsync({
            name: configName,
            data: configCopy,
            commit_message: commitMessage,
            ...(saveAsNewVersion ? {} : { version: config.data.version })
        }).then(() => {
            toast.success("Config updated successfully!");
            setCommitMessage("");
            configVersionHistory.refetch();
            config.refetch();
        }).catch((err) => {
            toast.error("Oops! Something went wrong. Please try again");
        })
    }

    const handleCreateNewVariationClick = () => {
        let url = frontendconfig.backend_url + "/config/variations?config_name=" + configName + "&variation=" + newConfigVariation;
        fetchWithAuth(url, {
            method: "POST"
        })
        .then(() => {
            toast.success("Variation created successfully!");
            setNewConfigVariation(false);
            configVariations.refetch();
        })
        .catch(() => {
            toast.error("Oops! Something went wrong. Please try again");
        })
    }

    const handleDeleteVariationClick = () => {
        if(confirm("Are you sure you want to delete this variation?")) {
            let url = frontendconfig.backend_url + "/config/variations?config_name=" + configName + "&variation=" + selectedVariation;
            fetchWithAuth(url, {
                method: "DELETE"
            })
            .then(() => {
                toast.success("Variation deleted successfully!");
                setSelectedVariation("default");
                configVariations.refetch();
            })
            .catch(() => {
                toast.error("Oops! Something went wrong. Please try again");
            })
        }
     }

    if (config.isLoading || configSchema.isLoading || configVariations.isLoading || configVersionHistory.isLoading) {
        return <Loader />
    }

    return (
        <Box sx={{ mt: 5, px: 4 }}>


            <Box display="flex" sx={{ alignItems: "center" }}>
                <Tabs value={tab} onChange={(e, t) => setTab(t)} sx={{ mb: 2 }}>
                    <Tab label="Configuration" />
                    <Tab label="Version History" />
                </Tabs>
                <Box sx={{ flexGrow: 1 }} />

                <Button onClick={() => setNewConfigVariation("")}>
                    Create New Variation
                </Button>

                <Button
                    disabled={selectedVariation == "default"}
                    color="error"
                    onClick={handleDeleteVariationClick}
                >
                    Delete Variation
                </Button>

                <FormControl sx={{ width: 200 }}>
                    <InputLabel>Variation</InputLabel>
                    <Select
                        size='small'
                        label="Variation"
                        value={selectedVariation}
                        onChange={(e) => setSelectedVariation(e.target.value)}
                    >
                        {configVariations?.data?.map(v => (
                            <MenuItem value={v}>{v}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            {tab == 0 && (
                <>
                    <Box sx={{ display: "flex", gap: 3, width: "-webkit-fill-available", height: "700px" }}>
                        <JSONEditor
                            value={config.data.data}
                            onChange={(newConfigCopy) => { console.log(newConfigCopy, setConfigCopy(newConfigCopy)) }}
                            mode="code"
                            schema={configSchema?.data?.data}
                            onValidChange={setIsConfigValid}
                        />
                        <JSONEditor
                            value={configSchema?.data?.data}
                            mode="view"
                        />

                    </Box>

                    <Box sx={{ display: "flex", mt: 3, gap: 2 }}>
                        <TextField
                            placeholder='Commit message'
                            value={commitMessage}
                            onChange={(e) => setCommitMessage(e.target.value)}
                            fullWidth
                        />
                        <Button
                            variant='contained'
                            sx={{ width: 100 }}
                            onClick={() => handleSaveClick(false)}
                            disabled={!isConfigValid || commitMessage.length == 0 || updateConfig.isLoading}
                        >
                            {updateConfig.isLoading ? "Updating..." : "Update"}
                        </Button>
                        <Button
                            variant='contained'
                            sx={{ width: 254 }}
                            onClick={() => handleSaveClick(true)}
                            disabled={!isConfigValid || commitMessage.length == 0 || updateConfig.isLoading}
                        >
                            {updateConfig.isLoading ? "Saving..." : "Save As New Version"}
                        </Button>
                    </Box>
                </>
            )}

            {tab == 1 && (
                <>
                    <DataGrid
                        columns={[
                            { field: 'timestamp', headerName: 'Timestamp', width: 200, valueGetter: (params) => (new Date(params.value)).toLocaleString() },
                            { field: 'version', headerName: 'Version', width: 100 },
                            { field: 'commit_message', headerName: 'Commit Message', flex: 1 },
                            { field: 'commit_by', headerName: 'Commit By', width: 150 },
                        ]}
                        rows={configVersionHistory.data.map(r => ({ ...r, id: r.timestamp }))}
                    />
                </>
            )}


            <Dialog open={newConfigVariation !== false} onClose={() => setNewConfigVariation(false)} maxWidth="sm" fullWidth>
                <DialogTitle>Create Variation</DialogTitle>
                <DialogContent>
                    <TextField
                        sx={{ my: 2 }}
                        label="Variation Name"
                        value={newConfigVariation}
                        onChange={(e) => setNewConfigVariation(e.target.value)}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setNewConfigVariation(false)}>
                        Cancel
                    </Button>
                    <Button onClick={handleCreateNewVariationClick} disabled={newConfigVariation == "" || configVariations?.data?.includes(newConfigVariation)}>
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default Config;