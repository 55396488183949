import config from "config";
import { useQuery } from "@tanstack/react-query";
import useFetchWithAuth from "../useFetchWithAuth";

const useSavedQueryResults = (savedQueryName) => {
    const fetchWithAuth = useFetchWithAuth();

    const fetchSavedQueryResults = async () => {

        const url = config.backend_url + "/analytics/run_saved_query?query_name=" + savedQueryName;
        const res = await fetchWithAuth(url).then(res => res.json());
        return res;
    }

    return useQuery(["savedQueryResults"], fetchSavedQueryResults);
}
  
export default useSavedQueryResults;