import { createContext, useContext, useState, useEffect } from "react";

import { supabase } from "../supbaseClient";

const SessionContext = createContext("loading");

export const SessionProvider = ({ children }) => {
  const [session, setSession] = useState("loading");

  useEffect(() => {
    supabase.auth
      .getSession()
      .then((session) => {
        setSession(session.data.session);
      })
      .catch(() => {
        setSession(null);
      });
    supabase.auth.onAuthStateChange((_, session) => {
      setSession(session);
    });
  }, []);

  return (
    <SessionContext.Provider value={session}>
      {children}
    </SessionContext.Provider>
  );
};

const useSession = () => useContext(SessionContext);

export default useSession;
