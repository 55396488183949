import React from 'react';
import { useOutlet, Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Loader from 'components/Loader/Loader';
import HomeLayout from 'components/HomeLayout/HomeLayout';
import { Helmet } from 'react-helmet';
import useConfig from 'hooks/api/useConfig';

const Index = () => {

    const outlet = useOutlet();
    const location = useLocation();
    const configs = useConfig("saved_analytics_queries");

    if (configs.isLoading) {
        return <Loader />;
    }

    const savedQueryNames = Object.keys(configs?.data?.data ?? {})

    const navLinks = [
        {
            sectionName: "Saved Queries",
            items: savedQueryNames?.map(saved_query_name => ({
                displayName: saved_query_name,
                pathname: "/saved_queries/" + saved_query_name
            }))
        }
    ]

    if(location.pathname == "/saved_queries/" && savedQueryNames.length > 0) {
        return <Navigate to={"/saved_queries/" + savedQueryNames?.[0]} />
    }
    
    return (
        <>
            <HomeLayout
                navLinks={navLinks}
            >
                {outlet}
            </HomeLayout>
        </>
    );
}


export default Index;