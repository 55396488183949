import { OutlinedInput } from "@mui/material";

export default function AuthInput(props) {
  return (
    <OutlinedInput
      {...props}
      sx={{
        marginBottom: 2,
        height: 38,
        borderRadius: 2,
        fontSize: 14,
      }}
    />
  );
}
