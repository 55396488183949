import { useMutation, useQueryClient } from "@tanstack/react-query";
import useFetchWithAuth from "../useFetchWithAuth";
import config from "config";


const useUpdatePromptTemplate = (name, forceProd = false) => {

    const fetchWithAuth = useFetchWithAuth();
    const queryClient = useQueryClient()

    const cacheKey = ["promptTemplate", name];

    const updateFormData = async (formData) => {
        let url = forceProd ? config.production_backend_url : config.backend_url;
        url += "/prompt_templates/" + name;
        
        let payload = {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        }
        return await fetchWithAuth(url, payload).then(res => res.json());
    }

    return useMutation({
        mutationFn: updateFormData,
        onMutate: async (newPromptTemplate) => {
            await queryClient.cancelQueries({ queryKey: cacheKey });
            const previousFormData = queryClient.getQueryData(cacheKey);

            queryClient.setQueryData(cacheKey, (old) => ({
                ...old,
                ...newPromptTemplate
            }));

            return { previousFormData };
        },

        onError: (err, newPromptTemplate, context) => {
            queryClient.setQueryData(cacheKey, context.previousFormData)
        },
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: cacheKey })
        },
    })
}

export default useUpdatePromptTemplate;