import { Close } from "@mui/icons-material";
import { Alert, Box, Button, Grid, IconButton, Paper, TextField, Typography } from "@mui/material";
import useUpdateExperiment from "hooks/api/useUpdateExperiment";
import useFetchWithAuth from "hooks/useFetchWithAuth";
import { useState } from "react";
import config from "config";
import useExperiments from "hooks/api/useExperiments";
import { toast } from "react-toastify";

const Experiment = ({ experiment, isNewExperiment, handleClose }) => {

    const fetchWithAuth = useFetchWithAuth();
    const experiments = useExperiments();
    const updateExperiment = useUpdateExperiment(isNewExperiment ? null : experiment._id);

    const [experimentCopy, setExperimentCopy] = useState(experiment);
    const [isLoading, setIsLoading] = useState(false);

    const handleCreateExperimentClick = () => {
        let url = config.backend_url + "/internal_experiments/";
        setIsLoading(true);

        fetchWithAuth(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ...experimentCopy,
                status: "live"
            })
        })
            .then(() => {
                toast.success("Experiment saved successfully");
                handleClose?.();
            })
            .catch(() => {
                toast.error("Unable to save experiment. Please try again.");
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleUpdateExperimentClick = () => {
        setIsLoading(true);
        updateExperiment.mutateAsync(experimentCopy)
            .then(() => {
                toast.success("Experiment saved successfully");
                experiments.refetch();
                handleClose?.();
            })
            .catch(() => {
                toast.error("Unable to save experiment. Please try again.");
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const disableSave =
        isLoading ||
        experimentCopy?.name == "" ||
        experimentCopy?.description == "" ||
        experimentCopy?.objective == "" ||
        experimentCopy?.groups.length < 2 ||
        experimentCopy?.groups?.some(group => group.name == "") ||
        experimentCopy?.groups?.some(group => group.weight == "") ||
        experimentCopy?.groups?.some(group => group.treatment_defenition.variation_name == "") ||
        experimentCopy?.groups?.some(group => group.weight < 0 || group.weight > 100)

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        sx={{ my: 2 }}
                        fullWidth
                        size="small"
                        label="Name"
                        value={experimentCopy?.name}
                        onChange={(e) => setExperimentCopy({ ...experimentCopy, name: e.target.value })}
                        disabled={experimentCopy?.status == "completed"}
                    />
                </Grid>

                <Grid item xs={6}>
                    <Box display="flex" sx={{ alignItems: "center", height: 40, my: 2 }}>
                        <Typography sx={{ mr: 2 }}>
                            Status:
                        </Typography>

                        <Typography color="primary" sx={{ textDecoration: "underline" }}>
                            {experimentCopy?.status}
                        </Typography>

                        {experimentCopy?.status == "draft" && (
                            <Alert color="info" sx={{ ml: 2 }}>Will be converted to "live" once published</Alert>
                        )}

                        {experimentCopy?.status == "live" && (
                            <Button onClick={() => setExperimentCopy({ ...experimentCopy, status: "completed" })}>Close</Button>
                        )}
                    </Box>
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        sx={{ my: 2 }}
                        fullWidth
                        multiline
                        minRows={2}
                        size="small"
                        label="Description"
                        value={experimentCopy?.description}
                        onChange={(e) => setExperimentCopy({ ...experimentCopy, description: e.target.value })}
                        disabled={experimentCopy?.status == "completed"}
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        sx={{ my: 2 }}
                        fullWidth
                        multiline
                        minRows={2}
                        size="small"
                        label="Objective"
                        value={experimentCopy?.objective}
                        onChange={(e) => setExperimentCopy({ ...experimentCopy, objective: e.target.value })}
                        disabled={experimentCopy?.status == "completed"}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        sx={{ my: 2 }}
                        fullWidth
                        multiline
                        minRows={2}
                        size="small"
                        label="Result Query"
                        value={experimentCopy?.result_query}
                        onChange={(e) => setExperimentCopy({ ...experimentCopy, result_query: e.target.value })}
                    />
                </Grid>

                <Grid item xs={12}>

                    <Typography>Groups</Typography>

                    {experimentCopy?.groups?.map(group => (
                        <Paper variant="outlined" sx={{ p: 2, my: 2 }}>
                            <Box display="flex" justifyContent="space-between">
                                <Typography>ID: {group?.id}</Typography>
                                <IconButton onClick={() => setExperimentCopy({ ...experimentCopy, groups: experimentCopy?.groups?.filter(g => g.id != group.id) })}>
                                    <Close />
                                </IconButton>
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        sx={{ my: 2 }}
                                        fullWidth
                                        label="Group Name"
                                        value={group?.name}
                                        onChange={(e) => setExperimentCopy({ ...experimentCopy, groups: experimentCopy?.groups?.map(g => g.id == group.id ? { ...g, name: e.target.value } : g) })}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        sx={{ my: 2 }}
                                        fullWidth
                                        type="number"
                                        label="Weight"
                                        value={group?.weight}
                                        onChange={(e) => setExperimentCopy({ ...experimentCopy, groups: experimentCopy?.groups?.map(g => g.id == group.id ? { ...g, weight: e.target.value } : g) })}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        sx={{ my: 2 }}
                                        fullWidth
                                        label="Config Variation Name"
                                        value={group?.treatment_defenition?.variation_name}
                                        onChange={(e) => setExperimentCopy({ ...experimentCopy, groups: experimentCopy?.groups?.map(g => g.id == group.id ? { ...g, treatment_defenition: { ...g.treatment_defenition, variation_name: e.target.value } } : g) })}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    ))}

                    <Button
                        onClick={() => setExperimentCopy({ ...experimentCopy, groups: [...experimentCopy?.groups, { id: (Math.random() + 1).toString(36).substring(7), name: "", treatment_defenition: { type: "use_config_variation", variation_name: "" } }] })}
                    >
                        Add Group
                    </Button>

                </Grid>

            </Grid>

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mt: 2
                }}
            >
                {isNewExperiment ? (
                    <>
                        <Button onClick={() => handleClose?.()}>
                            Close
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleCreateExperimentClick}
                            disabled={disableSave}
                        >
                            {isLoading ? "Creating..." : "Create"}
                        </Button>
                    </>
                ) : (
                    <>
                        {experimentCopy?.status != "completed" && (
                            <Button
                                variant="contained"
                                onClick={handleUpdateExperimentClick}
                                disabled={disableSave}
                            >
                                {isLoading ? "Updating..." : "Update"}
                            </Button>
                        )}
                    </>
                )}

            </Box>
        </Box>
    )
}

export default Experiment;