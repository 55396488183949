import { Box, CircularProgress } from "@mui/material"

const Loader = (props = {}) => {
    return (
        <Box sx={{ 
            width: "-webkit-fill-available", 
            display: "flex",
            flexDirection: "column",
            alignItems: "center", 
            justifyContent: "center",
            minHeight: "300px",
            height: "-webkit-fill-available"
        }}
        {...props}
        >
            <CircularProgress sx={props?.cpSx ?? {}} />
        </Box>
    )
}

export default Loader;