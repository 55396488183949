import config from "config";
import { useQuery } from "@tanstack/react-query";
import useFetchWithAuth from "../useFetchWithAuth";

const useConfigVariations = (name) => {
    const fetchWithAuth = useFetchWithAuth();

    const url = config.backend_url + "/config/variations/" + name;

    const fetchConfigVariations = async () => {

        if(!name) {
            return;
        }
        const res = await fetchWithAuth(url).then(res => res.json());
        return res;
    }

    return useQuery(["configVariations", name], fetchConfigVariations);
}

export default useConfigVariations;