import React from 'react';
import { useOutlet, Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Loader from 'components/Loader/Loader';
import HomeLayout from 'components/HomeLayout/HomeLayout';
import { Helmet } from 'react-helmet';
import useConfigs from 'hooks/api/useConfigs';

const Index = () => {

    const outlet = useOutlet();
    const configs = useConfigs();

    if (configs.isLoading) {
        return <Loader />;
    }

    const navLinks = [
        {
            sectionName: "Experiments",
            items: [
                {
                    displayName: "Experiments",
                    pathname: "/experiments"
                }
            ]
        }
    ]

    return (
        <>
            <HomeLayout
                navLinks={navLinks}
            >
                {outlet}
            </HomeLayout>
        </>
    );
}


export default Index;